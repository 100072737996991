import { Link } from "react-router-dom";
import styled from "styled-components";

const FooterContainer = styled.footer`
	position: relative;
	display: flex;
	flex-direction: column;
	background-color: var(--color-background-dark);
	color: var(--color-text-on-dark) !important;
	padding: 12px;
	font-size: 14px;
	font-weight: normal;
	letter-spacing: 0px;
	line-height: 20px;
	-webkit-user-select: none; /* Safari */
	user-select: none;
`;

const RowContainer = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 24px;

	div {
		display: flex;
		flex-direction: column;
		flex: 1 1;
		text-align: center;
		min-width: 200px;
	}

	h4 {
		font-size: 16px;
		font-weight: bold;
		letter-spacing: 0px;
		line-height: 20px;
		margin-bottom: 6px;
	}

	p {
		color: var(--color-text-on-dark) !important;
		text-align: center;
		-webkit-user-select: text; /* Safari */
		user-select: text;
	}
`;

const BottomLink = styled(Link)`
	color: var(--color-text-on-dark) !important;
	font-size: 14px;
	font-weight: normal;
	letter-spacing: 0px;
	line-height: 20px;
	text-decoration: none;
	transition: 0.3s;

	&:hover {
		color: var(--color-primary);
		text-decoration: underline;
	}
`;

const CopyrightLine = styled.span`
	display: flex;
	text-align: center;
	justify-content: center;
	margin: 24px 0 6px;
`;

const SocialLine = styled.div`
	display: flex;
	flex-direction: row;
	gap: 36px;
	justify-content: center;

	a {
		font-size: 24px;
		text-decoration: none;
		color: var(--color-text-on-dark) !important;
		transition: 0.3s;

		&:hover {
			color: var(--color-primary) !important;
		}
	}
`;

/*
<BottomLink to="/archivio-articoli">
	Archivio articoli
</BottomLink>
*/

function FooterBar() {
	return (
		<FooterContainer>
			<RowContainer>
				<div>
					<h4>Accesso rapido</h4>
					<BottomLink to="/diventare-membro">
						Diventa membro
					</BottomLink>
					<BottomLink to="/diventare-sponsor">
						Diventa sponsor
					</BottomLink>
					<BottomLink to="/cortei-story">Cortei story</BottomLink>
					<BottomLink to="/regolamento-corteo-castel-san-pietro">
						Regolamento corteo CSP
					</BottomLink>
				</div>
				<div>
					<h4>Donazione</h4>
					<p>
						Desideri sostenere il nostro gruppo senza essere un
						menbro?
						<br />
						Fai una donazione a piacimento!
						<br />
						<br />
						IBAN: CH71 8080 8004 3833 5248 7
					</p>
				</div>
				<div>
					<h4>Indirizzo</h4>
					<p>
						La Castello Bene
						<br />
						Casella Postale 59
						<br />
						CH-6874 Castel San Pietro
					</p>
				</div>
			</RowContainer>
			<CopyrightLine>
				Copyright © {new Date().getFullYear()} La Castello Bene. Tutti i diritti riservati.
			</CopyrightLine>
			<SocialLine>
				<a
					href="https://www.facebook.com/lacastellobene/"
					title="Seguici su Facebook"
				>
					<i class="fi fi-brands-facebook"></i>
				</a>
				<a
					href="https://www.instagram.com/lacastellobene/"
					title="Seguici su Instagram"
				>
					<i class="fi fi-brands-instagram"></i>
				</a>
				<a
					href="https://www.flickr.com/photos/170258017@N06/"
					title="Seguici su Flickr"
				>
					<i class="fi fi-brands-flickr"></i>
				</a>
				<a
					href="https://www.youtube.com/channel/UCpXwaEQGbHE60o09Fvs0cFw"
					title="Seguici su YouTube"
				>
					<i class="fi fi-brands-youtube"></i>
				</a>
			</SocialLine>
		</FooterContainer>
	);
}

export default FooterBar;
